import React from 'react';
import './styleEmptyList.css';

const EmptyList
 = () => {
  return (
    <div className='emptyList-wrapper'>
      {/* <img src='/assets/images/13525-empty(2).gif' alt='Empty gif' /> */}
      <h2 className='myh2 sorry'>I'm sorry... </h2>
      <br></br>
      <p>There is no post with that key word. <br />Click 'Home' and search again.</p>
    </div>
  )
}

export default EmptyList

