import React from 'react';
import './styleSearchBar.css'

const SearchBar = ({value, handleSearchKey, clearSearch, formSubmit}) => (
    <div className='searchBar-wrapper'>
      <form onSubmit={formSubmit}>
        <input 
        type='text'
        onChange={handleSearchKey} 
        placeholder='Bar by bar, ES, etc...' 
        value={value}
        />
        {value && <span className='clearSpan' onClick={clearSearch}>clear</span>}

        <button>Search</button>
      
      </form>
    </div>
  );

export default SearchBar
