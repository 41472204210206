import React from 'react';
import { Link } from 'react-router-dom';
import './styleBlogItem.css';
import Chip from '../../../../common/Chip'

const BlogItem = ({blog:{
  id, 
  tradingDate, 
  sessionNetProfit,
  sessionInstrument, 
  authorName, 
  authorAvatar, 
  category,
  instrument1,
  tradeDescription,
 }
}) => (
  <div className='blogItem-wrapper'>
      <h4 className='tradingDate myh4 center'><br />{tradingDate}</h4>
      <h5 className='sessionInstrument myh5 center'><br />{sessionInstrument}</h5>
      <h5 className='sessionNetProfit myh5 center'><br />{sessionNetProfit}</h5>
      <div className='hideChip'><Chip label={category} /></div>
      <Link className='' to={`/blog/${id}`}>
        <img className='blogItem-cover' src={instrument1} alt="Full session pic" />
        <div className='startText textBody'>Analysis
          <p className='blogItem-description'>{tradeDescription}</p>
        </div>
        <h5 className='myh6 readMore'><span className='arrow'></span></h5>
        <div className='blockquoteBlogItem'>Trade what you see, not what you want to see!</div>
      </Link>

      <footer className='footer-wrapper'>
          <div className='blogItem-author'>
            <img src={authorAvatar} alt="avatar" />
            <div>
              <h6 className='myh6 avatarTraderName'>{authorName}</h6>
              <p className='avatarTradingDate'>{tradingDate}</p>
            </div>
          </div>
          <hr className='myhr'></hr>
      </footer>
    </div>
  );

export default BlogItem
