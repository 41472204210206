import React from 'react';
import './stylesHeader.css';

const Header = () => (
    <header className='home-header'>
        <h2 className='capitalize myh2 center'>My price action journey</h2>
        {/* <h1 className='headerH1'>My Daily Trade Analysis</h1> */}

        <h3 className='headerQuote'>The Kaizen trading philosophy</h3>
        <img className='kaizen' src="/assets/images/kaizenPhilosophy.png" alt="The Kaizen Philosophy" />
        <br /><br />
        <h5 className='myh5 dashMarks'>My path to continuous improvement</h5>
        <p className='myh5 kaizenText'>Kaizen is a Japanese philosophy of continuous improvement, which emphasizes the importance of making small, incremental changes to my trading strategy and trading habits in order for me to achieve long-term success. My focus lays on a small, steady progress rather than on big, drastic changes.</p> 
        <p className='myh5 kaizenText'>This approach is particularly useful for me as a trader, as the markets are constantly evolving and changing, therefore it is important for me to be able to adapt and improve in my response to these changes. I believe that the Kaizen mindset will be a powerful tool for me as a trader, when looking to improve my performance and to achieve greater success in my trading.</p>
        <p className='myh5 kaizenText'></p>
        <br />
        <h5 className='myh5 dashMarks'>What I'm trading</h5>
        <p className='myh5 kaizenText'>I am trading CL, GC, ES and NQ futures. On CL & GC I'm trading a 200 tick chart, on ES I'm trading a 2000 tick chart and on the NQ I'm trading the 1000 tick chart. I trade CL & GC during the London session and from the US open I trade all four instruments.</p>
        <br />
        <h5 className='myh5 dashMarks'>Learning inspiration</h5>
        <p className='myh5 kaizenText'>I follow the teachings of Al Brooks and Mack (PATsTrading)</p>
        <br />
        <h5 className='myh5 dashMarks'>Search tags</h5>
        <p className='myh5 instrumentSearchTags center'>'CL', 'GC', 'ES', 'NQ'</p>
        <p className='myh5 chartSearchTags center'>'Up', 'Down', 'Sideways', 'Range', 'Unclear', 'Mixed', or 'Bar by bar'</p>
        <p className='myh5 learningSearchTags center'>'The Trading Process', 'Laptop', 'Chart setup'</p>
    </header> 
);

export default Header
